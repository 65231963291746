@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=e0ddf46b-dd79-4548-b61f-8cbeddf9f674);
*{
  --amplify-primary-color: #0046ce;
  --amplify-primary-tint: #0046ce;
  --amplify-primary-shade: #0046ce;
}
@font-face {
  font-family: "Open Sans Light";
  src: url(/static/media/OpenSans-Regular.9a6cfa58.ttf) format("truetype")
}

@font-face {
  font-family: "Open Sans Regular";
  src: url(/static/media/OpenSans-Regular.9a6cfa58.ttf) format("truetype")
}

@font-face {
  font-family: "Open Sans Medium";
  src: url(/static/media/OpenSans-Medium.5b0a682f.ttf) format("truetype")
}

@font-face {
  font-family: "Open Sans Demi Bold";
  src: url(/static/media/OpenSans-SemiBold.dcf2e322.ttf) format("truetype")
}

@font-face {
  font-family: "Open Sans Demi Bold";
  src: url(/static/media/OpenSans-SemiBold.dcf2e322.ttf) format("truetype")
}

@font-face {
  font-family: "Open Sans Bold";
  src: url(/static/media/OpenSans-Bold.45847e90.ttf) format("truetype")
}
@font-face {
  font-family: 'MontBold';
  src: url(/static/media/MontBold.8812f243.eot);
  src: url(/static/media/MontBold.8812f243.eot) format("embedded-opentype"), url(/static/media/MontBold.5066c490.woff2) format("woff2"), url(/static/media/MontBold.5f7beaf7.woff) format("woff"), url(/static/media/MontBold.d19d04a1.ttf) format("truetype"), url(/static/media/MontBold.ca0cebfe.svg#MontBold) format("svg");
}

.modal-custom-sm {
  max-width: 440px !important;
}

.modal-custom-md {
  max-width: 540px !important;
}

.modal-custom-lg {
  max-width: 620px !important;
}

.modal-custom-xl {
  max-width: 760px !important;
}

.modal-custom-xxl {
  max-width: 820px !important;
}

.modal-custom-xxxl {
  max-width: 1000px !important;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0258ff;
  --secondary: #082344;
  --success: #5cb85c;
  --info: #0b889d;
  --warning: #ffb000;
  --danger: #ea4335;
  --light: #f8f9fa;
  --dark: #343a40;
  --tertiary: #edf1f5;
  --primary-button: #0258ff;
  --secondary-button: #082344;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Open Sans Regular", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #576c77;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.0315rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0258ff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #003db5;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.0315rem;
  font-family: "Open Sans Bold", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #082344;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 0.063rem;
  margin-bottom: 0.063rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0.063rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.0315rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.375rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.form-control {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbe3eb;
  border-radius: 0.25rem / 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #82acff;
  outline: 0;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #5cb85c;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(92, 184, 92, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #5cb85c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #5cb85c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #5cb85c;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #5cb85c;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #5cb85c;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #80c780;
  background-color: #80c780;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #5cb85c;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #5cb85c;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #5cb85c;
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ea4335;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(234, 67, 53, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea4335;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea4335' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea4335' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.2rem rgba(234, 67, 53, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ea4335;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea4335' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea4335' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.2rem rgba(234, 67, 53, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea4335;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ea4335;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ea4335;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ef6e63;
  background-color: #ef6e63;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(234, 67, 53, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ea4335;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ea4335;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ea4335;
  box-shadow: 0 0 0 0.2rem rgba(234, 67, 53, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #576c77;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem / 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #576c77;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-primary:hover {
  color: #fff;
  background-color: #004adb;
  border-color: #0046ce;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #004adb;
  border-color: #0046ce;
  box-shadow: 0 0 0 0.2rem rgba(40, 113, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0046ce;
  border-color: #0042c1;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 113, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary:hover {
  color: #fff;
  background-color: #041122;
  border-color: #030c16;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #041122;
  border-color: #030c16;
  box-shadow: 0 0 0 0.2rem rgba(45, 68, 96, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #030c16;
  border-color: #01060b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 68, 96, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-success:hover {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #48a648;
  border-color: #449d44;
  box-shadow: 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #409440;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 195, 116, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #0b889d;
  border-color: #0b889d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-info:hover {
  color: #fff;
  background-color: #086979;
  border-color: #085f6d;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #086979;
  border-color: #085f6d;
  box-shadow: 0 0 0 0.2rem rgba(48, 154, 172, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #0b889d;
  border-color: #0b889d;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #085f6d;
  border-color: #075461;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 154, 172, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffb000;
  border-color: #ffb000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-warning:hover {
  color: #212529;
  background-color: #d99600;
  border-color: #cc8d00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #d99600;
  border-color: #cc8d00;
  box-shadow: 0 0 0 0.2rem rgba(222, 155, 6, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffb000;
  border-color: #ffb000;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc8d00;
  border-color: #bf8400;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 155, 6, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-danger:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #e12717;
  border-color: #d62516;
  box-shadow: 0 0 0 0.2rem rgba(237, 95, 83, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #ca2315;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 95, 83, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-tertiary {
  color: #212529;
  background-color: #edf1f5;
  border-color: #edf1f5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-tertiary:hover {
  color: #212529;
  background-color: #d4dee7;
  border-color: #ccd8e3;
}

.btn-tertiary:focus, .btn-tertiary.focus {
  color: #212529;
  background-color: #d4dee7;
  border-color: #ccd8e3;
  box-shadow: 0 0 0 0.2rem rgba(206, 210, 214, 0.5);
}

.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: #212529;
  background-color: #edf1f5;
  border-color: #edf1f5;
}

.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: #212529;
  background-color: #ccd8e3;
  border-color: #c4d1de;
}

.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 210, 214, 0.5);
}

.btn-primary-button {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-primary-button:hover {
  color: #fff;
  background-color: #004adb;
  border-color: #0046ce;
}

.btn-primary-button:focus, .btn-primary-button.focus {
  color: #fff;
  background-color: #004adb;
  border-color: #0046ce;
  box-shadow: 0 0 0 0.2rem rgba(40, 113, 255, 0.5);
}

.btn-primary-button.disabled, .btn-primary-button:disabled {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
}

.btn-primary-button:not(:disabled):not(.disabled):active, .btn-primary-button:not(:disabled):not(.disabled).active,
.show > .btn-primary-button.dropdown-toggle {
  color: #fff;
  background-color: #0046ce;
  border-color: #0042c1;
}

.btn-primary-button:not(:disabled):not(.disabled):active:focus, .btn-primary-button:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 113, 255, 0.5);
}

.btn-secondary-button {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-secondary-button:hover {
  color: #fff;
  background-color: #041122;
  border-color: #030c16;
}

.btn-secondary-button:focus, .btn-secondary-button.focus {
  color: #fff;
  background-color: #041122;
  border-color: #030c16;
  box-shadow: 0 0 0 0.2rem rgba(45, 68, 96, 0.5);
}

.btn-secondary-button.disabled, .btn-secondary-button:disabled {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
}

.btn-secondary-button:not(:disabled):not(.disabled):active, .btn-secondary-button:not(:disabled):not(.disabled).active,
.show > .btn-secondary-button.dropdown-toggle {
  color: #fff;
  background-color: #030c16;
  border-color: #01060b;
}

.btn-secondary-button:not(:disabled):not(.disabled):active:focus, .btn-secondary-button:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 68, 96, 0.5);
}

.btn-outline-primary {
  color: #0258ff;
  border-color: #0258ff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 88, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0258ff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 88, 255, 0.5);
}

.btn-outline-secondary {
  color: #082344;
  border-color: #082344;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 35, 68, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #082344;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 35, 68, 0.5);
}

.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 184, 92, 0.5);
}

.btn-outline-info {
  color: #0b889d;
  border-color: #0b889d;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #0b889d;
  border-color: #0b889d;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 136, 157, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #0b889d;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0b889d;
  border-color: #0b889d;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(11, 136, 157, 0.5);
}

.btn-outline-warning {
  color: #ffb000;
  border-color: #ffb000;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffb000;
  border-color: #ffb000;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 176, 0, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffb000;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb000;
  border-color: #ffb000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 176, 0, 0.5);
}

.btn-outline-danger {
  color: #ea4335;
  border-color: #ea4335;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 67, 53, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ea4335;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 67, 53, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-tertiary {
  color: #edf1f5;
  border-color: #edf1f5;
}

.btn-outline-tertiary:hover {
  color: #212529;
  background-color: #edf1f5;
  border-color: #edf1f5;
}

.btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 241, 245, 0.5);
}

.btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
  color: #edf1f5;
  background-color: transparent;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-outline-tertiary.dropdown-toggle {
  color: #212529;
  background-color: #edf1f5;
  border-color: #edf1f5;
}

.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 241, 245, 0.5);
}

.btn-outline-primary-button {
  color: #0258ff;
  border-color: #0258ff;
}

.btn-outline-primary-button:hover {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
}

.btn-outline-primary-button:focus, .btn-outline-primary-button.focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 88, 255, 0.5);
}

.btn-outline-primary-button.disabled, .btn-outline-primary-button:disabled {
  color: #0258ff;
  background-color: transparent;
}

.btn-outline-primary-button:not(:disabled):not(.disabled):active, .btn-outline-primary-button:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-button.dropdown-toggle {
  color: #fff;
  background-color: #0258ff;
  border-color: #0258ff;
}

.btn-outline-primary-button:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-button:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(2, 88, 255, 0.5);
}

.btn-outline-secondary-button {
  color: #082344;
  border-color: #082344;
}

.btn-outline-secondary-button:hover {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
}

.btn-outline-secondary-button:focus, .btn-outline-secondary-button.focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 35, 68, 0.5);
}

.btn-outline-secondary-button.disabled, .btn-outline-secondary-button:disabled {
  color: #082344;
  background-color: transparent;
}

.btn-outline-secondary-button:not(:disabled):not(.disabled):active, .btn-outline-secondary-button:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary-button.dropdown-toggle {
  color: #fff;
  background-color: #082344;
  border-color: #082344;
}

.btn-outline-secondary-button:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-button:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary-button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 35, 68, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0258ff;
  text-decoration: none;
}

.btn-link:hover {
  color: #003db5;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem / 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem / 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0258ff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0046ce !important;
}

.bg-secondary {
  background-color: #082344 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #030c16 !important;
}

.bg-success {
  background-color: #5cb85c !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #449d44 !important;
}

.bg-info {
  background-color: #0b889d !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #085f6d !important;
}

.bg-warning {
  background-color: #ffb000 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc8d00 !important;
}

.bg-danger {
  background-color: #ea4335 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d62516 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-tertiary {
  background-color: #edf1f5 !important;
}

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #ccd8e3 !important;
}

.bg-primary-button {
  background-color: #0258ff !important;
}

a.bg-primary-button:hover, a.bg-primary-button:focus,
button.bg-primary-button:hover,
button.bg-primary-button:focus {
  background-color: #0046ce !important;
}

.bg-secondary-button {
  background-color: #082344 !important;
}

a.bg-secondary-button:hover, a.bg-secondary-button:focus,
button.bg-secondary-button:hover,
button.bg-secondary-button:focus {
  background-color: #030c16 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dbe3eb !important;
}

.border-top {
  border-top: 1px solid #dbe3eb !important;
}

.border-right {
  border-right: 1px solid #dbe3eb !important;
}

.border-bottom {
  border-bottom: 1px solid #dbe3eb !important;
}

.border-left {
  border-left: 1px solid #dbe3eb !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0258ff !important;
}

.border-secondary {
  border-color: #082344 !important;
}

.border-success {
  border-color: #5cb85c !important;
}

.border-info {
  border-color: #0b889d !important;
}

.border-warning {
  border-color: #ffb000 !important;
}

.border-danger {
  border-color: #ea4335 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-tertiary {
  border-color: #edf1f5 !important;
}

.border-primary-button {
  border-color: #0258ff !important;
}

.border-secondary-button {
  border-color: #082344 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.375rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle, .form-switch label .checkbox-icon:after {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix {
  overflow: hidden;
  *zoom: 1;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0px 1px 2px rgba(206, 211, 218, 0.7) !important;
}

.shadow {
  box-shadow: 0px 1px 3px rgba(206, 211, 218, 0.2) !important;
}

.shadow-lg {
  box-shadow: 0px 1px 4px rgba(206, 211, 218, 0.9) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.063rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.063rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.063rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.063rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.063rem !important;
}

.m-2 {
  margin: 0.126rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.126rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.126rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.126rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.126rem !important;
}

.m-3 {
  margin: 0.189rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.189rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.189rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.189rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.189rem !important;
}

.m-4 {
  margin: 0.252rem !important;
}

.mt-4,
.my-4 {
  margin-top: 0.252rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 0.252rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 0.252rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 0.252rem !important;
}

.m-5 {
  margin: 0.315rem !important;
}

.mt-5,
.my-5 {
  margin-top: 0.315rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 0.315rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 0.315rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 0.315rem !important;
}

.m-10 {
  margin: 0.63rem !important;
}

.mt-10,
.my-10 {
  margin-top: 0.63rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 0.63rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 0.63rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 0.63rem !important;
}

.m-15 {
  margin: 0.945rem !important;
}

.mt-15,
.my-15 {
  margin-top: 0.945rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 0.945rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 0.945rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 0.945rem !important;
}

.m-20 {
  margin: 1.26rem !important;
}

.mt-20,
.my-20 {
  margin-top: 1.26rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 1.26rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 1.26rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 1.26rem !important;
}

.m-25 {
  margin: 1.575rem !important;
}

.mt-25,
.my-25 {
  margin-top: 1.575rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 1.575rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 1.575rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 1.575rem !important;
}

.m-30 {
  margin: 1.89rem !important;
}

.mt-30,
.my-30 {
  margin-top: 1.89rem !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mr-30,
.mx-30 {
  margin-right: 1.89rem !important;
}

.mb-30,
.my-30 {
  margin-bottom: 1.89rem !important;
}

.ml-30,
.mx-30 {
  margin-left: 1.89rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.063rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.063rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.063rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.063rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.063rem !important;
}

.p-2 {
  padding: 0.126rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.126rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.126rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.126rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.126rem !important;
}

.p-3 {
  padding: 0.189rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.189rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.189rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.189rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.189rem !important;
}

.p-4 {
  padding: 0.252rem !important;
}

.pt-4,
.py-4 {
  padding-top: 0.252rem !important;
}

.pr-4,
.px-4 {
  padding-right: 0.252rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 0.252rem !important;
}

.pl-4,
.px-4 {
  padding-left: 0.252rem !important;
}

.p-5 {
  padding: 0.315rem !important;
}

.pt-5,
.py-5 {
  padding-top: 0.315rem !important;
}

.pr-5,
.px-5 {
  padding-right: 0.315rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 0.315rem !important;
}

.pl-5,
.px-5 {
  padding-left: 0.315rem !important;
}

.p-10 {
  padding: 0.63rem !important;
}

.pt-10,
.py-10 {
  padding-top: 0.63rem !important;
}

.pr-10,
.px-10 {
  padding-right: 0.63rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 0.63rem !important;
}

.pl-10,
.px-10 {
  padding-left: 0.63rem !important;
}

.p-15 {
  padding: 0.945rem !important;
}

.pt-15,
.py-15 {
  padding-top: 0.945rem !important;
}

.pr-15,
.px-15 {
  padding-right: 0.945rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 0.945rem !important;
}

.pl-15,
.px-15 {
  padding-left: 0.945rem !important;
}

.p-20 {
  padding: 1.26rem !important;
}

.pt-20,
.py-20 {
  padding-top: 1.26rem !important;
}

.pr-20,
.px-20 {
  padding-right: 1.26rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 1.26rem !important;
}

.pl-20,
.px-20 {
  padding-left: 1.26rem !important;
}

.p-25 {
  padding: 1.575rem !important;
}

.pt-25,
.py-25 {
  padding-top: 1.575rem !important;
}

.pr-25,
.px-25 {
  padding-right: 1.575rem !important;
}

.pb-25,
.py-25 {
  padding-bottom: 1.575rem !important;
}

.pl-25,
.px-25 {
  padding-left: 1.575rem !important;
}

.p-30 {
  padding: 1.89rem !important;
}

.pt-30,
.py-30 {
  padding-top: 1.89rem !important;
}

.pr-30,
.px-30 {
  padding-right: 1.89rem !important;
}

.pb-30,
.py-30 {
  padding-bottom: 1.89rem !important;
}

.pl-30,
.px-30 {
  padding-left: 1.89rem !important;
}

.m-n1 {
  margin: -0.063rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.063rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.063rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.063rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.063rem !important;
}

.m-n2 {
  margin: -0.126rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.126rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.126rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.126rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.126rem !important;
}

.m-n3 {
  margin: -0.189rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.189rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.189rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.189rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.189rem !important;
}

.m-n4 {
  margin: -0.252rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -0.252rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -0.252rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -0.252rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -0.252rem !important;
}

.m-n5 {
  margin: -0.315rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -0.315rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -0.315rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -0.315rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -0.315rem !important;
}

.m-n10 {
  margin: -0.63rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -0.63rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -0.63rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -0.63rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -0.63rem !important;
}

.m-n15 {
  margin: -0.945rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -0.945rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -0.945rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -0.945rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -0.945rem !important;
}

.m-n20 {
  margin: -1.26rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -1.26rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -1.26rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -1.26rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -1.26rem !important;
}

.m-n25 {
  margin: -1.575rem !important;
}

.mt-n25,
.my-n25 {
  margin-top: -1.575rem !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -1.575rem !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -1.575rem !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -1.575rem !important;
}

.m-n30 {
  margin: -1.89rem !important;
}

.mt-n30,
.my-n30 {
  margin-top: -1.89rem !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -1.89rem !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -1.89rem !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -1.89rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.063rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.063rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.063rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.063rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.063rem !important;
  }
  .m-sm-2 {
    margin: 0.126rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.126rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.126rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.126rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.126rem !important;
  }
  .m-sm-3 {
    margin: 0.189rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.189rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.189rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.189rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.189rem !important;
  }
  .m-sm-4 {
    margin: 0.252rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 0.252rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 0.252rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 0.252rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 0.252rem !important;
  }
  .m-sm-5 {
    margin: 0.315rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 0.315rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 0.315rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 0.315rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 0.315rem !important;
  }
  .m-sm-10 {
    margin: 0.63rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 0.63rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 0.63rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 0.63rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 0.63rem !important;
  }
  .m-sm-15 {
    margin: 0.945rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 0.945rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 0.945rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 0.945rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 0.945rem !important;
  }
  .m-sm-20 {
    margin: 1.26rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.26rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.26rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.26rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.26rem !important;
  }
  .m-sm-25 {
    margin: 1.575rem !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 1.575rem !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 1.575rem !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 1.575rem !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 1.575rem !important;
  }
  .m-sm-30 {
    margin: 1.89rem !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 1.89rem !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 1.89rem !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 1.89rem !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 1.89rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.063rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.063rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.063rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.063rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.063rem !important;
  }
  .p-sm-2 {
    padding: 0.126rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.126rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.126rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.126rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.126rem !important;
  }
  .p-sm-3 {
    padding: 0.189rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.189rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.189rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.189rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.189rem !important;
  }
  .p-sm-4 {
    padding: 0.252rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 0.252rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 0.252rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 0.252rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 0.252rem !important;
  }
  .p-sm-5 {
    padding: 0.315rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 0.315rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 0.315rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 0.315rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 0.315rem !important;
  }
  .p-sm-10 {
    padding: 0.63rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 0.63rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 0.63rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 0.63rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 0.63rem !important;
  }
  .p-sm-15 {
    padding: 0.945rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 0.945rem !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 0.945rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 0.945rem !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 0.945rem !important;
  }
  .p-sm-20 {
    padding: 1.26rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.26rem !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.26rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.26rem !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.26rem !important;
  }
  .p-sm-25 {
    padding: 1.575rem !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 1.575rem !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 1.575rem !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 1.575rem !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 1.575rem !important;
  }
  .p-sm-30 {
    padding: 1.89rem !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 1.89rem !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 1.89rem !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 1.89rem !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 1.89rem !important;
  }
  .m-sm-n1 {
    margin: -0.063rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.063rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.063rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.063rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.063rem !important;
  }
  .m-sm-n2 {
    margin: -0.126rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.126rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.126rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.126rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.126rem !important;
  }
  .m-sm-n3 {
    margin: -0.189rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.189rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.189rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.189rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.189rem !important;
  }
  .m-sm-n4 {
    margin: -0.252rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.252rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.252rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.252rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.252rem !important;
  }
  .m-sm-n5 {
    margin: -0.315rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.315rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.315rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.315rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.315rem !important;
  }
  .m-sm-n10 {
    margin: -0.63rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -0.63rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -0.63rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -0.63rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -0.63rem !important;
  }
  .m-sm-n15 {
    margin: -0.945rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -0.945rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -0.945rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -0.945rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -0.945rem !important;
  }
  .m-sm-n20 {
    margin: -1.26rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -1.26rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -1.26rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -1.26rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -1.26rem !important;
  }
  .m-sm-n25 {
    margin: -1.575rem !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -1.575rem !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -1.575rem !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -1.575rem !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -1.575rem !important;
  }
  .m-sm-n30 {
    margin: -1.89rem !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -1.89rem !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -1.89rem !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -1.89rem !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -1.89rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.063rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.063rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.063rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.063rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.063rem !important;
  }
  .m-md-2 {
    margin: 0.126rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.126rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.126rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.126rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.126rem !important;
  }
  .m-md-3 {
    margin: 0.189rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.189rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.189rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.189rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.189rem !important;
  }
  .m-md-4 {
    margin: 0.252rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 0.252rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 0.252rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 0.252rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 0.252rem !important;
  }
  .m-md-5 {
    margin: 0.315rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 0.315rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 0.315rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 0.315rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 0.315rem !important;
  }
  .m-md-10 {
    margin: 0.63rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 0.63rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 0.63rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 0.63rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 0.63rem !important;
  }
  .m-md-15 {
    margin: 0.945rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 0.945rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 0.945rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 0.945rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 0.945rem !important;
  }
  .m-md-20 {
    margin: 1.26rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.26rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.26rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.26rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.26rem !important;
  }
  .m-md-25 {
    margin: 1.575rem !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 1.575rem !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 1.575rem !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 1.575rem !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 1.575rem !important;
  }
  .m-md-30 {
    margin: 1.89rem !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 1.89rem !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 1.89rem !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 1.89rem !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 1.89rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.063rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.063rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.063rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.063rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.063rem !important;
  }
  .p-md-2 {
    padding: 0.126rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.126rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.126rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.126rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.126rem !important;
  }
  .p-md-3 {
    padding: 0.189rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.189rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.189rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.189rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.189rem !important;
  }
  .p-md-4 {
    padding: 0.252rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 0.252rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 0.252rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 0.252rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 0.252rem !important;
  }
  .p-md-5 {
    padding: 0.315rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 0.315rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0.315rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 0.315rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0.315rem !important;
  }
  .p-md-10 {
    padding: 0.63rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 0.63rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 0.63rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 0.63rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 0.63rem !important;
  }
  .p-md-15 {
    padding: 0.945rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 0.945rem !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 0.945rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 0.945rem !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 0.945rem !important;
  }
  .p-md-20 {
    padding: 1.26rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.26rem !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.26rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.26rem !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.26rem !important;
  }
  .p-md-25 {
    padding: 1.575rem !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 1.575rem !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 1.575rem !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 1.575rem !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 1.575rem !important;
  }
  .p-md-30 {
    padding: 1.89rem !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 1.89rem !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 1.89rem !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 1.89rem !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 1.89rem !important;
  }
  .m-md-n1 {
    margin: -0.063rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.063rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.063rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.063rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.063rem !important;
  }
  .m-md-n2 {
    margin: -0.126rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.126rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.126rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.126rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.126rem !important;
  }
  .m-md-n3 {
    margin: -0.189rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.189rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.189rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.189rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.189rem !important;
  }
  .m-md-n4 {
    margin: -0.252rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.252rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.252rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.252rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.252rem !important;
  }
  .m-md-n5 {
    margin: -0.315rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.315rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.315rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.315rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.315rem !important;
  }
  .m-md-n10 {
    margin: -0.63rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -0.63rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -0.63rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -0.63rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -0.63rem !important;
  }
  .m-md-n15 {
    margin: -0.945rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -0.945rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -0.945rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -0.945rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -0.945rem !important;
  }
  .m-md-n20 {
    margin: -1.26rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -1.26rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -1.26rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -1.26rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -1.26rem !important;
  }
  .m-md-n25 {
    margin: -1.575rem !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -1.575rem !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -1.575rem !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -1.575rem !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -1.575rem !important;
  }
  .m-md-n30 {
    margin: -1.89rem !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -1.89rem !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -1.89rem !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -1.89rem !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -1.89rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.063rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.063rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.063rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.063rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.063rem !important;
  }
  .m-lg-2 {
    margin: 0.126rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.126rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.126rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.126rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.126rem !important;
  }
  .m-lg-3 {
    margin: 0.189rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.189rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.189rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.189rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.189rem !important;
  }
  .m-lg-4 {
    margin: 0.252rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 0.252rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 0.252rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 0.252rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 0.252rem !important;
  }
  .m-lg-5 {
    margin: 0.315rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 0.315rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 0.315rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 0.315rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 0.315rem !important;
  }
  .m-lg-10 {
    margin: 0.63rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 0.63rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 0.63rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 0.63rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 0.63rem !important;
  }
  .m-lg-15 {
    margin: 0.945rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 0.945rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 0.945rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 0.945rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 0.945rem !important;
  }
  .m-lg-20 {
    margin: 1.26rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.26rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.26rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.26rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.26rem !important;
  }
  .m-lg-25 {
    margin: 1.575rem !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 1.575rem !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 1.575rem !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 1.575rem !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 1.575rem !important;
  }
  .m-lg-30 {
    margin: 1.89rem !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 1.89rem !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 1.89rem !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 1.89rem !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 1.89rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.063rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.063rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.063rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.063rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.063rem !important;
  }
  .p-lg-2 {
    padding: 0.126rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.126rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.126rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.126rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.126rem !important;
  }
  .p-lg-3 {
    padding: 0.189rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.189rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.189rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.189rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.189rem !important;
  }
  .p-lg-4 {
    padding: 0.252rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 0.252rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 0.252rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 0.252rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 0.252rem !important;
  }
  .p-lg-5 {
    padding: 0.315rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 0.315rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 0.315rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 0.315rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 0.315rem !important;
  }
  .p-lg-10 {
    padding: 0.63rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 0.63rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 0.63rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 0.63rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 0.63rem !important;
  }
  .p-lg-15 {
    padding: 0.945rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 0.945rem !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 0.945rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 0.945rem !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 0.945rem !important;
  }
  .p-lg-20 {
    padding: 1.26rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.26rem !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.26rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.26rem !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.26rem !important;
  }
  .p-lg-25 {
    padding: 1.575rem !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 1.575rem !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 1.575rem !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 1.575rem !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 1.575rem !important;
  }
  .p-lg-30 {
    padding: 1.89rem !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 1.89rem !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 1.89rem !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 1.89rem !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 1.89rem !important;
  }
  .m-lg-n1 {
    margin: -0.063rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.063rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.063rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.063rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.063rem !important;
  }
  .m-lg-n2 {
    margin: -0.126rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.126rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.126rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.126rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.126rem !important;
  }
  .m-lg-n3 {
    margin: -0.189rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.189rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.189rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.189rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.189rem !important;
  }
  .m-lg-n4 {
    margin: -0.252rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.252rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.252rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.252rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.252rem !important;
  }
  .m-lg-n5 {
    margin: -0.315rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.315rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.315rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.315rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.315rem !important;
  }
  .m-lg-n10 {
    margin: -0.63rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -0.63rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -0.63rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -0.63rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -0.63rem !important;
  }
  .m-lg-n15 {
    margin: -0.945rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -0.945rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -0.945rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -0.945rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -0.945rem !important;
  }
  .m-lg-n20 {
    margin: -1.26rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -1.26rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -1.26rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -1.26rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -1.26rem !important;
  }
  .m-lg-n25 {
    margin: -1.575rem !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -1.575rem !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -1.575rem !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -1.575rem !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -1.575rem !important;
  }
  .m-lg-n30 {
    margin: -1.89rem !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -1.89rem !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -1.89rem !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -1.89rem !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -1.89rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.063rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.063rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.063rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.063rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.063rem !important;
  }
  .m-xl-2 {
    margin: 0.126rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.126rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.126rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.126rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.126rem !important;
  }
  .m-xl-3 {
    margin: 0.189rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.189rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.189rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.189rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.189rem !important;
  }
  .m-xl-4 {
    margin: 0.252rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 0.252rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 0.252rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 0.252rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 0.252rem !important;
  }
  .m-xl-5 {
    margin: 0.315rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 0.315rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 0.315rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 0.315rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 0.315rem !important;
  }
  .m-xl-10 {
    margin: 0.63rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 0.63rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 0.63rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 0.63rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 0.63rem !important;
  }
  .m-xl-15 {
    margin: 0.945rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 0.945rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 0.945rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 0.945rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 0.945rem !important;
  }
  .m-xl-20 {
    margin: 1.26rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.26rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.26rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.26rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.26rem !important;
  }
  .m-xl-25 {
    margin: 1.575rem !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 1.575rem !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 1.575rem !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 1.575rem !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 1.575rem !important;
  }
  .m-xl-30 {
    margin: 1.89rem !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 1.89rem !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 1.89rem !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 1.89rem !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 1.89rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.063rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.063rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.063rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.063rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.063rem !important;
  }
  .p-xl-2 {
    padding: 0.126rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.126rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.126rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.126rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.126rem !important;
  }
  .p-xl-3 {
    padding: 0.189rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.189rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.189rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.189rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.189rem !important;
  }
  .p-xl-4 {
    padding: 0.252rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 0.252rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 0.252rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 0.252rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 0.252rem !important;
  }
  .p-xl-5 {
    padding: 0.315rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 0.315rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 0.315rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 0.315rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 0.315rem !important;
  }
  .p-xl-10 {
    padding: 0.63rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 0.63rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 0.63rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 0.63rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 0.63rem !important;
  }
  .p-xl-15 {
    padding: 0.945rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 0.945rem !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 0.945rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 0.945rem !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 0.945rem !important;
  }
  .p-xl-20 {
    padding: 1.26rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.26rem !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.26rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.26rem !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.26rem !important;
  }
  .p-xl-25 {
    padding: 1.575rem !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 1.575rem !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 1.575rem !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 1.575rem !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 1.575rem !important;
  }
  .p-xl-30 {
    padding: 1.89rem !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 1.89rem !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 1.89rem !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 1.89rem !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 1.89rem !important;
  }
  .m-xl-n1 {
    margin: -0.063rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.063rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.063rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.063rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.063rem !important;
  }
  .m-xl-n2 {
    margin: -0.126rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.126rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.126rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.126rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.126rem !important;
  }
  .m-xl-n3 {
    margin: -0.189rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.189rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.189rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.189rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.189rem !important;
  }
  .m-xl-n4 {
    margin: -0.252rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.252rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.252rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.252rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.252rem !important;
  }
  .m-xl-n5 {
    margin: -0.315rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.315rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.315rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.315rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.315rem !important;
  }
  .m-xl-n10 {
    margin: -0.63rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -0.63rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -0.63rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -0.63rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -0.63rem !important;
  }
  .m-xl-n15 {
    margin: -0.945rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -0.945rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -0.945rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -0.945rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -0.945rem !important;
  }
  .m-xl-n20 {
    margin: -1.26rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -1.26rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -1.26rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -1.26rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -1.26rem !important;
  }
  .m-xl-n25 {
    margin: -1.575rem !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -1.575rem !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -1.575rem !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -1.575rem !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -1.575rem !important;
  }
  .m-xl-n30 {
    margin: -1.89rem !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -1.89rem !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -1.89rem !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -1.89rem !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -1.89rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.063rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.063rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.063rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.063rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.063rem !important;
  }
  .m-xxl-2 {
    margin: 0.126rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.126rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.126rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.126rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.126rem !important;
  }
  .m-xxl-3 {
    margin: 0.189rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 0.189rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 0.189rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 0.189rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 0.189rem !important;
  }
  .m-xxl-4 {
    margin: 0.252rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 0.252rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 0.252rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 0.252rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 0.252rem !important;
  }
  .m-xxl-5 {
    margin: 0.315rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 0.315rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 0.315rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 0.315rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 0.315rem !important;
  }
  .m-xxl-10 {
    margin: 0.63rem !important;
  }
  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 0.63rem !important;
  }
  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 0.63rem !important;
  }
  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 0.63rem !important;
  }
  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 0.63rem !important;
  }
  .m-xxl-15 {
    margin: 0.945rem !important;
  }
  .mt-xxl-15,
  .my-xxl-15 {
    margin-top: 0.945rem !important;
  }
  .mr-xxl-15,
  .mx-xxl-15 {
    margin-right: 0.945rem !important;
  }
  .mb-xxl-15,
  .my-xxl-15 {
    margin-bottom: 0.945rem !important;
  }
  .ml-xxl-15,
  .mx-xxl-15 {
    margin-left: 0.945rem !important;
  }
  .m-xxl-20 {
    margin: 1.26rem !important;
  }
  .mt-xxl-20,
  .my-xxl-20 {
    margin-top: 1.26rem !important;
  }
  .mr-xxl-20,
  .mx-xxl-20 {
    margin-right: 1.26rem !important;
  }
  .mb-xxl-20,
  .my-xxl-20 {
    margin-bottom: 1.26rem !important;
  }
  .ml-xxl-20,
  .mx-xxl-20 {
    margin-left: 1.26rem !important;
  }
  .m-xxl-25 {
    margin: 1.575rem !important;
  }
  .mt-xxl-25,
  .my-xxl-25 {
    margin-top: 1.575rem !important;
  }
  .mr-xxl-25,
  .mx-xxl-25 {
    margin-right: 1.575rem !important;
  }
  .mb-xxl-25,
  .my-xxl-25 {
    margin-bottom: 1.575rem !important;
  }
  .ml-xxl-25,
  .mx-xxl-25 {
    margin-left: 1.575rem !important;
  }
  .m-xxl-30 {
    margin: 1.89rem !important;
  }
  .mt-xxl-30,
  .my-xxl-30 {
    margin-top: 1.89rem !important;
  }
  .mr-xxl-30,
  .mx-xxl-30 {
    margin-right: 1.89rem !important;
  }
  .mb-xxl-30,
  .my-xxl-30 {
    margin-bottom: 1.89rem !important;
  }
  .ml-xxl-30,
  .mx-xxl-30 {
    margin-left: 1.89rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.063rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.063rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.063rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.063rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.063rem !important;
  }
  .p-xxl-2 {
    padding: 0.126rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.126rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.126rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.126rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.126rem !important;
  }
  .p-xxl-3 {
    padding: 0.189rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 0.189rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 0.189rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 0.189rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 0.189rem !important;
  }
  .p-xxl-4 {
    padding: 0.252rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 0.252rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 0.252rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 0.252rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 0.252rem !important;
  }
  .p-xxl-5 {
    padding: 0.315rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 0.315rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 0.315rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 0.315rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 0.315rem !important;
  }
  .p-xxl-10 {
    padding: 0.63rem !important;
  }
  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 0.63rem !important;
  }
  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 0.63rem !important;
  }
  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 0.63rem !important;
  }
  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 0.63rem !important;
  }
  .p-xxl-15 {
    padding: 0.945rem !important;
  }
  .pt-xxl-15,
  .py-xxl-15 {
    padding-top: 0.945rem !important;
  }
  .pr-xxl-15,
  .px-xxl-15 {
    padding-right: 0.945rem !important;
  }
  .pb-xxl-15,
  .py-xxl-15 {
    padding-bottom: 0.945rem !important;
  }
  .pl-xxl-15,
  .px-xxl-15 {
    padding-left: 0.945rem !important;
  }
  .p-xxl-20 {
    padding: 1.26rem !important;
  }
  .pt-xxl-20,
  .py-xxl-20 {
    padding-top: 1.26rem !important;
  }
  .pr-xxl-20,
  .px-xxl-20 {
    padding-right: 1.26rem !important;
  }
  .pb-xxl-20,
  .py-xxl-20 {
    padding-bottom: 1.26rem !important;
  }
  .pl-xxl-20,
  .px-xxl-20 {
    padding-left: 1.26rem !important;
  }
  .p-xxl-25 {
    padding: 1.575rem !important;
  }
  .pt-xxl-25,
  .py-xxl-25 {
    padding-top: 1.575rem !important;
  }
  .pr-xxl-25,
  .px-xxl-25 {
    padding-right: 1.575rem !important;
  }
  .pb-xxl-25,
  .py-xxl-25 {
    padding-bottom: 1.575rem !important;
  }
  .pl-xxl-25,
  .px-xxl-25 {
    padding-left: 1.575rem !important;
  }
  .p-xxl-30 {
    padding: 1.89rem !important;
  }
  .pt-xxl-30,
  .py-xxl-30 {
    padding-top: 1.89rem !important;
  }
  .pr-xxl-30,
  .px-xxl-30 {
    padding-right: 1.89rem !important;
  }
  .pb-xxl-30,
  .py-xxl-30 {
    padding-bottom: 1.89rem !important;
  }
  .pl-xxl-30,
  .px-xxl-30 {
    padding-left: 1.89rem !important;
  }
  .m-xxl-n1 {
    margin: -0.063rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.063rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.063rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.063rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.063rem !important;
  }
  .m-xxl-n2 {
    margin: -0.126rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.126rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.126rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.126rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.126rem !important;
  }
  .m-xxl-n3 {
    margin: -0.189rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -0.189rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -0.189rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -0.189rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -0.189rem !important;
  }
  .m-xxl-n4 {
    margin: -0.252rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -0.252rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -0.252rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -0.252rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -0.252rem !important;
  }
  .m-xxl-n5 {
    margin: -0.315rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -0.315rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -0.315rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -0.315rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -0.315rem !important;
  }
  .m-xxl-n10 {
    margin: -0.63rem !important;
  }
  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -0.63rem !important;
  }
  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -0.63rem !important;
  }
  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -0.63rem !important;
  }
  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -0.63rem !important;
  }
  .m-xxl-n15 {
    margin: -0.945rem !important;
  }
  .mt-xxl-n15,
  .my-xxl-n15 {
    margin-top: -0.945rem !important;
  }
  .mr-xxl-n15,
  .mx-xxl-n15 {
    margin-right: -0.945rem !important;
  }
  .mb-xxl-n15,
  .my-xxl-n15 {
    margin-bottom: -0.945rem !important;
  }
  .ml-xxl-n15,
  .mx-xxl-n15 {
    margin-left: -0.945rem !important;
  }
  .m-xxl-n20 {
    margin: -1.26rem !important;
  }
  .mt-xxl-n20,
  .my-xxl-n20 {
    margin-top: -1.26rem !important;
  }
  .mr-xxl-n20,
  .mx-xxl-n20 {
    margin-right: -1.26rem !important;
  }
  .mb-xxl-n20,
  .my-xxl-n20 {
    margin-bottom: -1.26rem !important;
  }
  .ml-xxl-n20,
  .mx-xxl-n20 {
    margin-left: -1.26rem !important;
  }
  .m-xxl-n25 {
    margin: -1.575rem !important;
  }
  .mt-xxl-n25,
  .my-xxl-n25 {
    margin-top: -1.575rem !important;
  }
  .mr-xxl-n25,
  .mx-xxl-n25 {
    margin-right: -1.575rem !important;
  }
  .mb-xxl-n25,
  .my-xxl-n25 {
    margin-bottom: -1.575rem !important;
  }
  .ml-xxl-n25,
  .mx-xxl-n25 {
    margin-left: -1.575rem !important;
  }
  .m-xxl-n30 {
    margin: -1.89rem !important;
  }
  .mt-xxl-n30,
  .my-xxl-n30 {
    margin-top: -1.89rem !important;
  }
  .mr-xxl-n30,
  .mx-xxl-n30 {
    margin-right: -1.89rem !important;
  }
  .mb-xxl-n30,
  .my-xxl-n30 {
    margin-bottom: -1.89rem !important;
  }
  .ml-xxl-n30,
  .mx-xxl-n30 {
    margin-left: -1.89rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: "Open Sans Light", sans-serif !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0258ff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #003db5 !important;
}

.text-secondary {
  color: #082344 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important;
}

.text-success {
  color: #5cb85c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #3d8b3d !important;
}

.text-info {
  color: #0b889d !important;
}

a.text-info:hover, a.text-info:focus {
  color: #064a56 !important;
}

.text-warning {
  color: #ffb000 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b37b00 !important;
}

.text-danger {
  color: #ea4335 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bf2114 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-tertiary {
  color: #edf1f5 !important;
}

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #bccbda !important;
}

.text-primary-button {
  color: #0258ff !important;
}

a.text-primary-button:hover, a.text-primary-button:focus {
  color: #003db5 !important;
}

.text-secondary-button {
  color: #082344 !important;
}

a.text-secondary-button:hover, a.text-secondary-button:focus {
  color: black !important;
}

.text-body {
  color: #576c77 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

button:focus,
button:active,
button:active:focus {
  outline: none;
  box-shadow: none;
}

.btn {
  font-family: "Open Sans Bold", sans-serif;
  height: 2.5rem;
  padding: 0rem 0.9375rem;
  line-height: 2.5rem;
  border-radius: 4px;
}

.btn .btn-icon {
  display: inline-block;
  position: relative;
  top: -0.0625rem;
}

.btn.btn-xl {
  font-size: 0.875rem;
  line-height: 3.125rem;
  height: 3.125rem;
  padding: 0rem 1.5625rem;
}

.btn.btn-xl .spinner {
  max-width: 1.375rem;
}

.btn.btn-lg {
  font-size: 0.875rem;
  line-height: 2.75rem;
  height: 2.75rem;
  padding: 0rem 1.25rem;
}

.btn.btn-lg .spinner {
  max-width: 1.375rem;
}

.btn.btn-md {
  font-size: 0.875rem;
  line-height: 2.5rem;
  height: 2.5rem;
  padding: 0rem 0.9375rem;
}

.btn.btn-md .spinner {
  max-width: 1.375rem;
}

.btn.btn-sm {
  font-size: 0.75rem;
  line-height: 2.25rem;
  height: 2.25rem;
}

.btn.btn-sm .spinner {
  max-width: 1.25rem;
}

.btn.btn-xs {
  padding: 0rem 0.625rem;
  font-size: 0.625rem;
  height: 1.625rem;
  line-height: 1.625rem;
}

.btn.btn-xs .spinner {
  max-width: 1.125rem;
}

.btn.btn-outline {
  color: #082344;
  border: 0.0625rem solid #dbe3eb;
}

.btn.btn-outline:hover, .btn.btn-outline:focus {
  color: #0258ff;
  border-color: #0258ff;
}

.btn.btn-outline.hover-danger:hover {
  border-color: #ea4335;
  color: #ea4335;
}

.btn.btn-outline-danger {
  color: #082344;
  border: 0.0625rem solid #dbe3eb;
}

.btn.btn-outline-danger:hover {
  background: transparent;
  color: #ea4335;
  border: 0.0625rem solid #ea4335;
}

.btn.btn-outline-danger:active {
  color: #ea4335 !important;
  background-color: transparent !important;
}

.btn.btn-danger {
  border: 0.0625rem solid #ea4335;
}

.btn.btn-danger:hover {
  border-color: #ea4335;
}

.btn.btn-danger:hover svg {
  fill: #ea4335;
}

.btn.btn-primary-outline {
  color: #0258ff;
  text-decoration: none;
  border: 0.0625rem solid #0258ff;
}

.btn.btn-primary-outline:hover {
  color: white;
  background-color: #0258ff;
}

.btn.btn-view {
  color: #0258ff;
  text-decoration: none;
  background: rgba(2, 88, 255, 0.1);
}

.btn.btn-view:hover {
  color: #0258ff;
  background: rgba(2, 88, 255, 0.2);
}

.btn.btn-shadow:hover {
  box-shadow: 0 0.375rem 0.625rem rgba(8, 178, 250, 0.38);
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
}

.btn:focus, .btn:active, .btn:not(:disabled):not(.disabled):active:focus {
  outline: none;
  box-shadow: none;
}

.btn-icon {
  position: relative;
  display: inline-block;
}

.custom-btn-width {
  width: 100%;
}

@media (min-width: 576px) {
  .custom-btn-width {
    width: 7.375rem;
    min-width: 7.375rem;
  }
  .custom-btn-width-sm {
    width: 80px;
    min-width: 80px;
  }
}

@media (max-width: 575px) {
  .btn-width-full-xs {
    width: 100%;
  }
}

.form-control {
  border-radius: 0.25rem;
  box-shadow: none;
  font-size: 0.875rem;
  border-color: #dbe3eb;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #082344;
  opacity: 0.8;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #082344;
  opacity: 0.8;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(8, 35, 68, 0.8);
  /*color: $color;
        opacity: $opacity;*/
}

.form-control::-ms-input-placeholder {
  /* Firefox 18- */
  color: #082344;
  opacity: 0.8;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #082344;
  opacity: 0.8;
}

.form-control[type=number]::-webkit-inner-spin-button, .form-control[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

.form-control[type=number] {
  -moz-appearance: textfield;
}

.form-control.form-control-xl {
  height: 3.125rem;
}

.form-control.form-control-lg {
  height: 2.75rem;
}

.form-control.form-control-md {
  height: 2.5rem;
}

.form-control.form-control-sm {
  height: 2.25rem;
}

.text-as-placeholder {
  color: rgba(8, 35, 68, 0.8);
}

input.form-control.form-control-xl {
  height: auto;
  min-height: 3.125rem;
}

input.form-control.form-control-lg {
  height: auto;
  min-height: 2.75rem;
}

input.form-control.form-control-md {
  height: auto;
  min-height: 2.5rem;
}

input.form-control.form-control-sm {
  height: auto;
  min-height: 2.25rem;
}

select.form-control {
  -webkit-appearance: none;
          appearance: none;
  padding-right: 1.875rem;
  background: url("https://dufzo4epsnvlh.cloudfront.net/image/icons/arrow-thin-down.svg") no-repeat calc(100% - 15px) center;
}

.form-group sup {
  color: #ea4335;
  padding: 0 0.1875rem;
}

.form-group .react-datepicker-wrapper {
  display: block;
}

.form-group .datepicker {
  cursor: pointer;
  background: url("https://gosite-assets.s3-us-west-1.amazonaws.com/image/activity-icons/calendar-dark.svg") no-repeat calc(100% - 15px) center;
  background-size: 1.375rem;
}

.form-group .prevent-typing {
  cursor: pointer !important;
  color: transparent;
  text-shadow: 0px 0px 0px #495057;
}

.form-group .prevent-typing:focus {
  cursor: pointer !important;
}

.form-group .label-text {
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #082344;
  font-family: "Open Sans Bold", sans-serif;
}

.form-group .label-text.lowercase-text {
  font-size: 1rem;
}

.form-group .form-error {
  color: #ea4335;
  margin-left: 0.5rem;
  font-size: 0.6875rem;
  margin-bottom: 0.3125rem;
  display: inline-block;
  padding: 0.1875rem 0.625rem;
  border: 0.0625rem solid #ea4335;
  font-family: "Open Sans Bold", sans-serif;
  border-radius: 0.2rem;
}

.form-group .star {
  color: #ea4335;
  font-family: "Open Sans Regular", sans-serif;
  font-style: normal;
}

.form-success {
  background-color: #f4fff4;
  border: 0.0625rem solid #5cb85c;
  color: #5cb85c;
  font-size: 0.6875rem;
  font-family: "Open Sans Bold", sans-serif;
  border-radius: 0.2rem;
  padding: 0.375rem 0.625rem;
  display: inline-block;
  padding: 0.1875rem 0.625rem;
  text-align: center;
}

.b-search-field {
  position: relative;
}

.b-search-field .form-control {
  font-size: 0.75rem;
  min-width: 10rem;
  border-radius: 0.25rem;
}

.b-search-field .btn-search {
  position: absolute;
  right: 0;
  top: 0;
}

.b-search-field .btn-search img {
  display: block;
}

.b-search-field .custom-search-input {
  padding-right: 2.5rem;
}

.form-check {
  padding: 0;
  color: #082344;
}

.form-check label {
  position: relative;
  margin: 0;
  cursor: pointer;
}

.form-check label input {
  position: absolute;
  z-index: -1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

.form-check label input:checked + .checkbox-icon {
  border-color: #dbe3eb;
  background: #0258ff url("https://dufzo4epsnvlh.cloudfront.net/image/icon-new/tick-icon.svg") no-repeat center;
  background-size: 0.75rem;
  border: 0;
}

.form-check label input[disabled] + .checkbox-icon {
  background-color: #e7e7e7;
  background: #e7e7e7;
  border: solid 0.0625rem #ccc;
  cursor: not-allowed;
}

.form-check label input[disabled][checked] + .checkbox-icon {
  background: #ccc url("https://dufzo4epsnvlh.cloudfront.net/image/icon-new/tick-icon.svg") no-repeat center;
  background-size: 0.75rem;
}

.form-check label .checkbox-icon {
  vertical-align: middle;
  display: inline-block;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  border: 0.0625rem solid #dbe3eb;
  border-radius: 0.2rem;
  background: white;
  position: relative;
  top: -0.125rem;
}

.form-check label .checkbox-icon:hover {
  border: 0.125rem solid #0258ff;
  transition: all 0.1s linear;
}

.form-check--has-label label {
  padding-left: 30px;
}

.form-check--has-label label .checkbox-icon {
  position: absolute;
  left: 0;
  top: 0.125rem;
}

.form-radio {
  padding: 0;
  color: #082344;
  position: relative;
  padding-left: 1.75rem;
  margin: 0;
  cursor: pointer;
}

.form-radio input {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

.form-radio input:checked + .radio-icon {
  border-color: #0258ff;
}

.form-radio input:checked + .radio-icon::after {
  background-color: #0258ff;
}

.form-radio input[disabled] + .radio-icon {
  background-color: #e7e7e7;
  background: #e7e7e7;
  border: solid 0.0625rem #ccc;
  cursor: not-allowed;
}

.form-radio input[disabled][checked] + .radio-icon::after {
  background-color: #bbb;
}

.form-radio .radio-icon {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.0625rem solid #dde0e4;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  transition: all 0.2s linear;
}

.form-radio .radio-icon::after {
  content: '';
  background-color: transparent;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1.125rem;
  transition: all 0.2s linear;
}

.form-switch label {
  position: relative;
  margin: 0;
  cursor: pointer;
}

.form-switch label .checkbox-icon {
  vertical-align: middle;
  display: inline-block;
  width: 1.875rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #e9ecef;
  position: relative;
  top: -0.125rem;
}

.form-switch label .checkbox-icon:after {
  width: 1.125rem;
  height: 1.125rem;
  display: block;
  content: '';
  left: -0.125rem;
  position: absolute;
  background: white;
  transition: all 0.1s linear;
  box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.199304);
}

.form-switch label input {
  position: absolute;
  z-index: -1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
}

.form-switch label input:checked + .checkbox-icon {
  background: #0258ff;
}

.form-switch label input:checked + .checkbox-icon::after {
  background: white;
  left: 0.8125rem;
}

.form-switch--has-label label {
  padding-left: 45px;
}

.form-switch--has-label label .btn-icon:not(.btn-icon--children) {
  position: static;
}

.form-switch--has-label label .checkbox-icon {
  position: absolute;
  left: 0;
  top: 0.125rem;
}

.form-switch--size-lg label .checkbox-icon {
  width: 3.75rem;
  height: 1.75rem;
  border-radius: 1.75rem;
  top: -0.125rem;
}

.form-switch--size-lg label .checkbox-icon:after {
  width: 1.75rem;
  height: 1.75rem;
  left: -0.125rem;
  top: 0;
}

.form-switch--size-lg label input:checked + .checkbox-icon::after {
  left: 2.125rem;
}

.add-btn {
  width: 100%;
}

.cancel-btn {
  color: #082344;
  border: 0.0625rem solid #dbe3eb;
}

.cancel-btn:hover {
  color: #082344;
  border-color: #082344;
}

@media (min-width: 576px) {
  .add-btn {
    width: auto;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: normal;
}

h1 {
  font-size: 1.25rem;
  font-family: "MontBold";
}

@media (min-width: 576px) {
  h1 {
    font-size: 1.625rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.875rem;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-size: 1.25rem;
  font-family: "MontBold";
}

h3 {
  font-size: 1.125rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.8125rem;
}

h6 {
  font-size: 0.75rem;
}

strong, b {
  font-family: "Open Sans Bold", sans-serif;
  font-weight: normal;
}

.border-primary-hover:hover {
  border: 0.0625rem solid #0258ff !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-grab {
  cursor: grab !important;
}

.gu-transit {
  cursor: grabbing !important;
}

.has-short-text {
  font-size: 1.625rem !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ced4da #e9ecef;
  overflow: auto;
}

.overflow-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
  visibility: hidden;
  opacity: 0;
}

.overflow-scrollbar::-webkit-scrollbar-track {
  background: white;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.overflow-scrollbar::-webkit-scrollbar-thumb {
  background: none;
}

.overflow-scrollbar::-webkit-scrollbar-thumb:hover {
  background: none;
}

.overflow-scrollbar:hover::-webkit-scrollbar {
  visibility: visible;
  opacity: 1;
}

.overflow-scrollbar:hover::-webkit-scrollbar-track {
  background: #e9ecef;
}

.overflow-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #ced4da;
}

.overflow-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background: #e9ecef;
}

.overflow-y-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ced4da #e9ecef;
  overflow: hidden;
  overflow-y: auto;
}

.overflow-y-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
  visibility: hidden;
  opacity: 0;
}

.overflow-y-scrollbar::-webkit-scrollbar-track {
  background: white;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.overflow-y-scrollbar::-webkit-scrollbar-thumb {
  background: none;
}

.overflow-y-scrollbar::-webkit-scrollbar-thumb:hover {
  background: none;
}

.overflow-y-scrollbar:hover::-webkit-scrollbar {
  visibility: visible;
  opacity: 1;
}

.overflow-y-scrollbar:hover::-webkit-scrollbar-track {
  background: #e9ecef;
}

.overflow-y-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #ced4da;
}

.overflow-y-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background: #e9ecef;
}

.overflow-x-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ced4da #e9ecef;
  overflow: hidden;
  overflow-x: auto;
}

.overflow-x-scrollbar::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
  visibility: hidden;
  opacity: 0;
}

.overflow-x-scrollbar::-webkit-scrollbar-track {
  background: white;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.overflow-x-scrollbar::-webkit-scrollbar-thumb {
  background: none;
}

.overflow-x-scrollbar::-webkit-scrollbar-thumb:hover {
  background: none;
}

.overflow-x-scrollbar:hover::-webkit-scrollbar {
  visibility: visible;
  opacity: 1;
}

.overflow-x-scrollbar:hover::-webkit-scrollbar-track {
  background: #e9ecef;
}

.overflow-x-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #ced4da;
}

.overflow-x-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background: #e9ecef;
}

.max-height-100 {
  max-height: 6.25rem !important;
}

.max-height-200 {
  max-height: 12.5rem !important;
}

.max-height-300 {
  max-height: 18.75rem !important;
}

.max-height-50vh {
  max-height: 50vh !important;
}

.max-height-100vh {
  max-height: 100vh !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.line-height-normal {
  line-height: normal !important;
}

.height-auto {
  height: auto !important;
}

.height-50 {
  height: 3.125rem !important;
}

.height-100 {
  height: 6.25rem !important;
}

.height-200 {
  height: 12.5rem !important;
}

.height-250 {
  height: 15.625rem !important;
}

.h-auto {
  height: auto !important;
}

.width-auto {
  width: auto !important;
}

.width-50 {
  width: 3.125rem !important;
}

.width-100 {
  width: 6.25rem !important;
}

.width-125 {
  width: 7.8125rem !important;
}

.width-150 {
  width: 9.375rem !important;
}

.width-200 {
  width: 12.5rem !important;
}

.width-250 {
  width: 15.625rem !important;
}

.width-300 {
  width: 18.75rem !important;
}

.width-100-percentage {
  width: 100% !important;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.font-weight-bolder {
  font-family: "MontBold" !important;
  font-weight: normal !important;
}

.font-weight-bold {
  font-family: "Open Sans Bold", sans-serif !important;
  font-weight: normal !important;
}

.font-weight-light {
  font-family: "Open Sans Light", sans-serif !important;
  font-weight: normal !important;
}

.font-weight-regular {
  font-family: "Open Sans Regular", sans-serif !important;
  font-weight: normal !important;
}

.font-family-base {
  font-family: "Open Sans Regular", sans-serif !important;
}

.gray-600 {
  color: #6c757d !important;
}

.primary-font {
  font-family: "Open Sans Regular", sans-serif !important;
}

.primary-font-light {
  font-family: "Open Sans Light", sans-serif !important;
}

.primary-font-bold {
  font-family: "MontBold" !important;
}

.secondary-font {
  font-family: "Open Sans Light", sans-serif !important;
}

.secondary-font-medium {
  font-family: "Open Sans Medium", sans-serif !important;
}

.secondary-font-bold {
  font-family: "Open Sans Bold", sans-serif !important;
}

.secondary-font-light {
  font-family: "Open Sans Light", sans-serif !important;
}

.tertiary-font {
  font-family: "Open Sans Demi Bold", sans-serif !important;
}

.light-text-color {
  color: #576c77 !important;
}

.light-gray {
  color: #8290A1 !important;
}

.mt-6 {
  margin-top: 0.375rem !important;
}

.mt-7 {
  margin-top: 0.4375rem !important;
}

.mt-8 {
  margin-top: 0.5rem !important;
}

.mt-9 {
  margin-top: 0.5625rem !important;
}

.mb-6 {
  margin-bottom: 0.375rem !important;
}

.mb-7 {
  margin-bottom: 0.4375rem !important;
}

.mb-8 {
  margin-bottom: 0.5rem !important;
}

.mb-9 {
  margin-bottom: 0.5625rem !important;
}

.top-0 {
  top: 0 !important;
}

.top-1 {
  top: 0.0625rem !important;
}

.top-2 {
  top: 0.125rem !important;
}

.top-3 {
  top: 0.1875rem !important;
}

.top-4 {
  top: 0.25rem !important;
}

.top-5 {
  top: 0.3125rem !important;
}

.top-10 {
  top: 0.625rem !important;
}

.top-minus-1 {
  top: -0.0625rem !important;
}

.top-minus-2 {
  top: -0.125rem !important;
}

.top-minus-3 {
  top: -0.1875rem !important;
}

.top-minus-4 {
  top: -0.25rem !important;
}

.top-minus-5 {
  top: -0.3125rem !important;
}

.top-minus-10 {
  top: -0.625rem !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-1 {
  bottom: 0.0625rem !important;
}

.bottom-2 {
  bottom: 0.125rem !important;
}

.bottom-3 {
  bottom: 0.1875rem !important;
}

.bottom-4 {
  bottom: 0.25rem !important;
}

.bottom-5 {
  bottom: 0.3125rem !important;
}

.bottom-10 {
  bottom: 0.625rem !important;
}

.bottom-minus-1 {
  bottom: -0.0625rem !important;
}

.bottom-minus-2 {
  bottom: -0.125rem !important;
}

.bottom-minus-3 {
  bottom: -0.1875rem !important;
}

.bottom-minus-4 {
  bottom: -0.25rem !important;
}

.bottom-minus-5 {
  bottom: -0.3125rem !important;
}

.bottom-minus-10 {
  bottom: -0.625rem !important;
}

.left-0 {
  left: 0 !important;
}

.left-1 {
  left: 0.0625rem !important;
}

.left-2 {
  left: 0.125rem !important;
}

.left-3 {
  left: 0.1875rem !important;
}

.left-4 {
  left: 0.25rem !important;
}

.left-5 {
  left: 0.3125rem !important;
}

.left-10 {
  left: 0.625rem !important;
}

.left-minus-1 {
  left: -0.0625rem !important;
}

.left-minus-2 {
  left: -0.125rem !important;
}

.left-minus-3 {
  left: -0.1875rem !important;
}

.left-minus-4 {
  left: -0.25rem !important;
}

.left-minus-5 {
  left: -0.3125rem !important;
}

.left-minus-10 {
  left: -0.625rem !important;
}

.right-0 {
  right: 0 !important;
}

.right-1 {
  right: 0.0625rem !important;
}

.right-2 {
  right: 0.125rem !important;
}

.right-3 {
  right: 0.1875rem !important;
}

.right-4 {
  right: 0.25rem !important;
}

.right-5 {
  right: 0.3125rem !important;
}

.right-10 {
  right: 0.625rem !important;
}

.right-minus-1 {
  right: -0.0625rem !important;
}

.right-minus-2 {
  right: -0.125rem !important;
}

.right-minus-3 {
  right: -0.1875rem !important;
}

.right-minus-4 {
  right: -0.25rem !important;
}

.right-minus-5 {
  right: -0.3125rem !important;
}

.right-minus-10 {
  right: -0.625rem !important;
}

.border-color-light {
  border-color: #EDF1F5 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff !important;
}

.text-black {
  color: #000 !important;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.fz-10 {
  font-size: 0.625rem !important;
}

.fz-12 {
  font-size: 0.75rem !important;
}

.fz-13 {
  font-size: 0.8125rem !important;
}

.fz-14 {
  font-size: 0.875rem !important;
}

.fz-15 {
  font-size: 0.9375rem !important;
}

.fz-16 {
  font-size: 1rem !important;
}

.fz-18 {
  font-size: 1.125rem !important;
}

.fz-20 {
  font-size: 1.25rem !important;
}

.fz-22 {
  font-size: 1.375rem !important;
}

.fz-24 {
  font-size: 1.5rem !important;
}

.gutter-5 {
  margin-right: -2.5px;
  margin-left: -2.5px;
}

.gutter-5 > .col,
.gutter-5 > [class*="col-"] {
  padding-right: 2.5px;
  padding-left: 2.5px;
}

.gutter-10 {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > .col,
.gutter-10 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-16 {
  margin-right: -8px;
  margin-left: -8px;
}

.gutter-16 > .col,
.gutter-16 > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}

.gutter-20 {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > .col,
.gutter-20 > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-24 {
  margin-right: -12px;
  margin-left: -12px;
}

.gutter-24 > .col,
.gutter-24 > [class*="col-"] {
  padding-right: 12px;
  padding-left: 12px;
}

.shadow-md {
  box-shadow: 0px 2px 8px rgba(206, 211, 218, 0.2) !important;
}

.shadow-xl {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12) !important;
}

.shadow-darken {
  box-shadow: 0px 1px 3px rgba(206, 211, 218, 0.8) !important;
}

.shadow-lighten {
  box-shadow: 0px 7px 32px rgba(206, 211, 218, 0.3) !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.transform-none {
  -webkit-transform: none !important;
          transform: none !important;
}

@media (min-width: 576px) {
  .ml-minus-5 {
    margin-left: -5px !important;
  }
  .ml-minus-10 {
    margin-left: -10px !important;
  }
  .mr-minus-5 {
    margin-right: -5px !important;
  }
  .mr-minus-10 {
    margin-right: -10px !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-sm-top-0 {
    border-top: none !important;
  }
  .border-sm-right-0 {
    border-right: none !important;
  }
  .border-sm-bottom-0 {
    border-bottom: none !important;
  }
  .border-sm-left-0 {
    border-left: none !important;
  }
  .border-sm {
    border: 0.0625rem solid #dbe3eb !important;
  }
  .border-sm-top {
    border-top: 0.0625rem solid #dbe3eb !important;
  }
  .border-sm-right {
    border-right: 0.0625rem solid #dbe3eb !important;
  }
  .border-sm-bottom {
    border-bottom: 0.0625rem solid #dbe3eb !important;
  }
  .border-sm-left {
    border-left: 0.0625rem solid #dbe3eb !important;
  }
  .width-sm-200 {
    width: 12.5rem !important;
  }
}

@media (min-width: 768px) {
  .border-md-0 {
    border: 0 !important;
  }
  .border-md-top-0 {
    border-top: none !important;
  }
  .border-md-right-0 {
    border-right: none !important;
  }
  .border-md-bottom-0 {
    border-bottom: none !important;
  }
  .border-md-left-0 {
    border-left: none !important;
  }
  .border-md {
    border: 0.0625rem solid #dbe3eb !important;
  }
  .border-md-top {
    border-top: 0.0625rem solid #dbe3eb !important;
  }
  .border-md-right {
    border-right: 0.0625rem solid #dbe3eb !important;
  }
  .border-md-bottom {
    border-bottom: 0.0625rem solid #dbe3eb !important;
  }
  .border-md-left {
    border-left: 0.0625rem solid #dbe3eb !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .width-md-125 {
    width: 7.8125rem !important;
  }
  .width-md-150 {
    width: 9.375rem !important;
  }
}

@media (min-width: 992px) {
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-top-0 {
    border-top: none !important;
  }
  .border-lg-right-0 {
    border-right: none !important;
  }
  .border-lg-bottom-0 {
    border-bottom: none !important;
  }
  .border-lg-left-0 {
    border-left: none !important;
  }
  .border-lg {
    border: 0.0625rem solid #dbe3eb !important;
  }
  .border-lg-top {
    border-top: 0.0625rem solid #dbe3eb !important;
  }
  .border-lg-right {
    border-right: 0.0625rem solid #dbe3eb !important;
  }
  .border-lg-bottom {
    border-bottom: 0.0625rem solid #dbe3eb !important;
  }
  .border-lg-left {
    border-left: 0.0625rem solid #dbe3eb !important;
  }
}

@media (min-width: 1200px) {
  .has-short-text {
    font-size: 2.25rem !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-top-0 {
    border-top: none !important;
  }
  .border-xl-right-0 {
    border-right: none !important;
  }
  .border-xl-bottom-0 {
    border-bottom: none !important;
  }
  .border-xl-left-0 {
    border-left: none !important;
  }
  .border-xl {
    border: 0.0625rem solid #dbe3eb !important;
  }
  .border-xl-top {
    border-top: 0.0625rem solid #dbe3eb !important;
  }
  .border-xl-right {
    border-right: 0.0625rem solid #dbe3eb !important;
  }
  .border-xl-bottom {
    border-bottom: 0.0625rem solid #dbe3eb !important;
  }
  .border-xl-left {
    border-left: 0.0625rem solid #dbe3eb !important;
  }
}

@media (max-width: 575.98px) {
  .border-xs-only {
    border: 1px solid #dbe3eb !important;
  }
  .border-top-xs-only {
    border-top: 1px solid #dbe3eb !important;
  }
  .border-right-xs-only {
    border-right: 1px solid #dbe3eb !important;
  }
  .border-bottom-xs-only {
    border-bottom: 1px solid #dbe3eb !important;
  }
  .border-left-xs-only {
    border-left: 1px solid #dbe3eb !important;
  }
  .border-color-light-xs-only {
    border-color: #EDF1F5 !important;
  }
  .border-xs-only-0 {
    border: 0 !important;
  }
  .border-top-xs-only-0 {
    border-top: 0 !important;
  }
  .border-right-xs-only-0 {
    border-right: 0 !important;
  }
  .border-bottom-xs-only-0 {
    border-bottom: 0 !important;
  }
  .border-left-xs-only-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #dbe3eb !important;
  }
  .border-sm-top {
    border-top: 1px solid #dbe3eb !important;
  }
  .border-sm-right {
    border-right: 1px solid #dbe3eb !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #dbe3eb !important;
  }
  .border-sm-left {
    border-left: 1px solid #dbe3eb !important;
  }
  .border-color-light {
    border-color: #EDF1F5 !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-right-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-left-sm-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-md {
    border: 1px solid #dbe3eb !important;
  }
  .border-md-top {
    border-top: 1px solid #dbe3eb !important;
  }
  .border-md-right {
    border-right: 1px solid #dbe3eb !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #dbe3eb !important;
  }
  .border-md-left {
    border-left: 1px solid #dbe3eb !important;
  }
  .border-color-light {
    border-color: #EDF1F5 !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-right-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-left-md-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #dbe3eb !important;
  }
  .border-lg-top {
    border-top: 1px solid #dbe3eb !important;
  }
  .border-lg-right {
    border-right: 1px solid #dbe3eb !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #dbe3eb !important;
  }
  .border-lg-left {
    border-left: 1px solid #dbe3eb !important;
  }
  .border-color-light {
    border-color: #EDF1F5 !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-right-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-left-lg-0 {
    border-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #dbe3eb !important;
  }
  .border-xl-top {
    border-top: 1px solid #dbe3eb !important;
  }
  .border-xl-right {
    border-right: 1px solid #dbe3eb !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #dbe3eb !important;
  }
  .border-xl-left {
    border-left: 1px solid #dbe3eb !important;
  }
  .border-color-light {
    border-color: #EDF1F5 !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-right-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-left-xl-0 {
    border-left: 0 !important;
  }
}

.max-width-1000 {
  max-width: 62.5rem;
}

.default-text-color {
  color: #576c77 !important;
}

.max-width-500 {
  max-width: 31.25rem;
}

.max-width-200 {
  max-width: 12.5rem;
}

.strike-through {
  text-decoration: line-through;
}

.outer-layout-main-wrapper {
  min-height: 100vh;
}

.outer-layout-main-wrapper .auth_page-auth {
  /* background: #fff;
  background: linear-gradient(124.29deg, #fcfdfd 36.13%, #f8f9fb); */
  min-height: 100vh;
}

.auth_header .auth-logo {
  width: 6.875rem;
}

.auth_master-form {
  width: 90%;
}

.auth_master-form .auth_content {
  padding: 1.25rem;
}

.auth_content_forgot_password {
  padding: 1.25rem;
}

.auth_master-form .auth_content.minWidth, .auth_content_forgot_password.minWidth {
  margin: 0 auto;
  max-width: 27.5rem;
}
.auth_master-form .btn-fb-custom {
  background: #3b5998;
}

.auth_master-form .auth_prepend-image {
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 1.375rem;
  height: 1.375rem;
}

.auth_master-form .auth_divider {
  margin-bottom: 1.25rem;
}

.auth_master-form .auth_divider:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
  height: .0625rem;
  background: #d1dbe1;
}

.auth_master-form .auth_divider .auth_divider-text {
  font-size: .875rem;
  z-index: 1;
  color: #576c77;
  background: #fff;
}

.auth_master-form .google-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.auth_master-form .auth_icon-field {
  padding-right: 3.125rem;
}

.auth_master-form .btn-outlook-custom:hover {
  border: solid 1px #54AB31;
  color: #54AB31;
}

.auth_icon-block {
  width: .625rem;
}

.auth_icon-block path {
  fill: #0258ff;
}

.auth_link span {
  color: #0258ff;
}
.auth_footer-copyright {
  margin-top: 40px;
}
.auth_footer-copyright .auth_foot-link {
  color: #576c77;
}

.auth_footer-copyright .auth_dot {
  width: .125rem;
  height: .125rem;
  background: #576c77;
}
/*# sourceMappingURL=style.css.map */

/* new signup page */
.strong {
  font-family: "Open Sans Bold", sans-serif;
}
.formIcon {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}
.newSignup input {
  padding-left: 38px;
}
.newSignup input::-webkit-input-placeholder {
  color: #666;
}
.newSignup input::placeholder {
  color: #666;
}
body[data-page='signup'].v1 {
  height: 100%;
  background: url(/static/media/v1_background.11597538.svg) no-repeat right 0px top 0px,linear-gradient(90deg, #fff 50%, #1041A3 50%);
}

body[data-page='signup'].v2 {
  background-image: url(/static/media/blue_background.53ee38f8.svg);
  background-repeat: no-repeat;
  background-position: right 0px top 0px;
}

.auth_header {
  margin-bottom: 40px;
}
.auth_header .auth-logo {
  width: rem(110);
}
.auth_master-form {
  width: 90%;
}
.auth_master-form .auth_content {
  background: #fff;
  padding: rem(40);
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(206, 211, 218, 0.8) !important;
}
.auth_content_forgot_password {
  background: #fff;
  padding: rem(40);
}
.auth_master-form .btn-fb-custom {
  background: #3b5998;
}
.auth_master-form .auth_prepend-image {
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1.375rem;
  height: 1.375rem;
}
.auth_master-form .auth_divider {
  margin-bottom: 1.25rem;
}
.auth_master-form .auth_divider:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  height: 0.0625rem;
  background: #d1dbe1;
}
.auth_master-form .auth_divider .auth_divider-text {
  font-size: 0.875rem;
  z-index: 1;
  color: #576c77;
  background: #fff;
}
.auth_master-form .google-icon {
  width: 1.25rem;
  height: 1.25rem;
}
.auth_master-form .auth_icon-field {
  padding-right: 3.125rem;
}
.auth_icon-block {
  width: 0.625rem;
}
.auth_icon-block path {
  fill: #0258ff;
}
.auth_link span {
  color: #0258ff;
}
.auth_footer-copyright .auth_foot-link {
  color: #576c77;
}
.auth_footer-copyright .auth_dot {
  width: 0.125rem;
  height: 0.125rem;
  background: #576c77;
}
.marketingMessage {
  text-align: center;
  color: #666565;
}
body[data-page='signup'].v1 .marketingMessage {
  padding-left: 20px;
}
body[data-page='signup'].v2 .marketingMessage {
  padding-left: 0px;
  padding-right: 20px;
}

.marketingMessage .h3 {
  font-weight: 550;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-family: "Open Sans Regular", sans-serif;
  margin: 1.5rem 0;
}
.gositeStatsImg {
  background: url(/static/media/gosite_stats.65641838.svg) no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  width: 100%;
  max-width: 480px;
  height: 124px;
}
.marketingMessage .checkmark_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1rem;
}
.marketingMessage .circle {
  position: relative;
  background: #fff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  border: 2px solid #0357ff;
  margin-right: 0.5rem;
}
.marketingMessage .checkMark {
  position: absolute;
  -webkit-transform: rotate(50deg) translate(-50%, -50%);
          transform: rotate(50deg) translate(-50%, -50%);
  left: 20%;
  top: 43%;
  height: 10px;
  width: 6px;
  border-bottom: 2px solid #0357ff;
  border-right: 2px solid #0357ff;
}
.marketingMessage .blueBullets {
  text-align: left;
}
.newSignup strong {
  color: #0357ff;
}
.marketingMessage blockquote {
  text-align: left;
  position: relative;
  margin: 0.5em;
  padding: 0.5rem 2rem 0.5rem 3rem;
}
.marketingMessage blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 6em;
  line-height: 1;
  top: 0;
  left: 0;
  color: #0357ff;
  content: "\201C";
}
.marketingMessage blockquote footer {
  padding: 0 1em 0 0;
  opacity: .65;
}
.marketingMessage blockquote cite:before {
}
h2 {
  font-size: 28px;
  font-family: "MontBold";
}
.row.gutter-30 {
  margin-left: -30px;
  margin-right: -30px;
}
.row.gutter-30 [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}


.ajax-loader
{
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 4px solid #0258ff;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin
{
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
    50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
    to   { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

@keyframes spin
{
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
    50%  { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
    to   { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

body[data-page='signup'].v1 .first { order: 2 }
body[data-page='signup'].v1 .second { order: 1 }
body[data-page='signup'].v2 .first { order: 1 }
body[data-page='signup'].v2 .second { order: 2 }


body[data-page='signup'].v1 .marketingMessage .circle {
  position: relative;
  background: #1041A3;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  border: 2px solid #fff;
  margin-right: 0.5rem;
}
body[data-page='signup'].v1 .marketingMessage .checkMark {
  position: absolute;
  -webkit-transform: rotate(50deg) translate(-50%, -50%);
          transform: rotate(50deg) translate(-50%, -50%);
  left: 20%;
  top: 43%;
  height: 10px;
  width: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
body[data-page='signup'].v1 .marketingMessage h2 {
  color: #fff;
}
body[data-page='signup'].v1 .marketingMessage .h3 {
  color: #fff;
}
body[data-page='signup'].v1 .marketingMessage {
  color: #fff;
}
body[data-page='signup'].v1 .marketingMessage strong {
  color: #fff;
}
body[data-page='signup'].v1 .roundedBox {
  background: #204faa;
  padding: 10px 25px;
  margin: 30px 0 60px 0;
  border-radius: 24px;
  text-align: center;
}
body[data-page='signup'].v2 .roundedBox {
  padding: 20px;
  margin: 50px 0;
  background: #fff;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0px 5px 10px rgba(206, 211, 218, 0.8);
  border-radius: 24px;
}
body[data-page='signup'].v1 .auth_footer-copyright .justify-content-center {
  position: absolute;
  left: calc(50% - 440px);
}

body[data-page='signup'].v1 .auth_master-form .auth_header{
  text-align: left !important;
}

body[data-page='signup'].v2 .auth_master-form .auth_header{
  text-align: left !important;
}
body[data-page='signup'].v1 .auth_content {
  border: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
}
body[data-page='signup'].v1 .marketingMessage blockquote:before {
  color: #fff;
}

body.loading {
  background: #fff !important;
}
body[data-page='signup'].v1.loading .auth_master-form .auth_header,
body[data-page='signup'].v2.loading .auth_master-form .auth_header {
  text-align: center !important;
} 
body.loading .auth_footer-copyright {
  visibility: hidden;
}

@media (max-width: 1200px) {
  .auth_master-form {
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .auth_header {
    margin-bottom: 10px;
  }
  body[data-page='signup'].v1 .marketingMessage {
    padding: 0px;
  }
  body[data-page='signup'].v2 .marketingMessage {
    padding: 0px;
  }
}

@media (max-width: 768px) {
  body[data-page='signup'].v2 .first { order: 2 }
  body[data-page='signup'].v2 .second { order: 1 }

  body[data-page='signup'].v1 .marketingMessage {
    padding: 0px;
  }
  body[data-page='signup'].v2 .marketingMessage {
    padding: 0px;
  }
  .auth_master-form {
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .gositeStatsImg {
    background: url(/static/media/gosite_stats_vertical.f50af421.svg) no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    max-width: 430px;
    height: 551px;
    width: auto;
  }
  .auth_header {
    margin-bottom: 10px;
  }
  body[data-page='signup'].v1 .marketingMessage {
    background: #1041A3;
  }
  body[data-page='signup'].v1 .auth_footer-copyright .justify-content-center {
    position: relative;
    left: auto;
    background: #fff;
  }
  body[data-page='signup'].v1 {
    background: none;
  }
  body[data-page='signup'].v1 .first {
    background: #1041A3;
  }
}

/*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */
